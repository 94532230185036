<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Водії</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        v-for="driver in data"
        :key="driver.mode"
        class="transaction-item"
      >
        <b-media no-body class="align-items-center">
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="resolveUserStatusVariant(driver.statusName)"
            >
              <feather-icon
                size="18"
                icon="UserIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h5 class="transaction-title">
              {{ resolveUserStatus(driver.statusName) }}
            </h5>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ driver.count }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const resolveUserStatus = status => {
        if (status === 'WAITING_FOR_USER_REGISTRATION') return 'Очікується реєстрація користувача'
        if (status === 'ACCOUNT_PROCESSING') return 'Обробка'
        if (status === 'WAITING_DRIVER_PIN_CONFIRMATION') return 'Очікується підтвердження PIN-коду'
        if (status === 'CONFIRMED') return 'Підтверджено'
        if (status === 'PENDING_DATEX_CREATION') return 'Очікується створення DATEX'
        if (status === 'PREPARED') return 'Готовий'
        if (status === 'ACTIVE') return 'Активний'
        if (status === 'BLOCKED') return 'Заблокований'
        return 'secondary'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'WAITING_FOR_USER_REGISTRATION') return 'light-secondary'
        if (status === 'ACCOUNT_PROCESSING') return 'light-secondary'
        if (status === 'WAITING_DRIVER_PIN_CONFIRMATION') return 'light-secondary'
        if (status === 'CONFIRMED') return 'light-primary'
        if (status === 'PENDING_DATEX_CREATION') return 'light-secondary'
        if (status === 'PREPARED') return 'light-info'
        if (status === 'ACTIVE') return 'light-success'
        if (status === 'BLOCKED') return 'light-danger'
        return 'secondary'
    }

    return {
      resolveUserStatus,
      resolveUserStatusVariant
    }
  }
}
</script>

<style scoped>

  .card-transaction .card-title {
    font-size: 16px !important;
  }

  .transaction-item .b-avatar {
    border-radius: 50% !important;
  }

  .transaction-title {
    font-size: 13px;
    margin-right: 10px;
  }

</style>