var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-home"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-row',{staticClass:"match-height centered"},[(_vm.isAllowedCashAccount)?_c('b-col',{attrs:{"lg":_vm.isAllowedAccount ? '6' : '12',"md":"4"}},[_c('home-order-chart',{key:String(_vm.isAllowedAccount),attrs:{"data":_vm.cash}})],1):_vm._e(),(_vm.isAllowedAccount)?_c('b-col',{attrs:{"lg":_vm.isAllowedCashAccount ? '6' : '12',"md":"4"}},[_c('home-profit-chart',{attrs:{"data":_vm.cashless}})],1):_vm._e(),(_vm.statistics.cards)?_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('home-earnings-chart',{attrs:{"data":{
            series:[
              _vm.statistics.cards.byTypeAccounts['1'],
              _vm.statistics.cards.byTypeAccounts['2']
            ]}}})],1):_vm._e()],1)],1),(_vm.statistics.drivers)?_c('b-col',{attrs:{"lg":"4"}},[_c('home-drivers',{attrs:{"data":_vm.statistics.drivers.byStatuses}})],1):_vm._e(),(_vm.statistics.balances)?_c('b-col',{staticClass:"home-balances",attrs:{"lg":"4","md":"4"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"6","md":"6","sm":"6"}},[_c('statistic-card-vertical',{attrs:{"icon":"DollarSignIcon","statistic":parseFloat(_vm.statistics.balances.total[1]/100).toFixed(2) + ' грн',"statistic-title":"Всього на готівкових рахунках","color":"info"}})],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"6"}},[(_vm.isAllowedAccount)?_c('statistic-card-vertical',{attrs:{"color":"warning","icon":"CreditCardIcon","statistic":parseFloat(_vm.statistics.balances.total[2]/100).toFixed(2) + ' грн',"statistic-title":"Всього на безготівкових рахунках"}}):_vm._e()],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"6"}},[_c('statistic-card-vertical',{attrs:{"color":"danger","icon":"DollarSignIcon","statistic":parseFloat(_vm.statistics.balances.totalDriversOnly[1]/100).toFixed(2) + ' грн',"statistic-title":"Всього на готівкових рахунках водіїв"}})],1),_c('b-col',{attrs:{"xl":"6","md":"6","sm":"6"}},[(_vm.isAllowedAccount)?_c('statistic-card-vertical',{attrs:{"color":"primary","icon":"CreditCardIcon","statistic":parseFloat(_vm.statistics.balances.totalDriversOnly[2]/100).toFixed(2) + ' грн',"statistic-title":"Всього на безготівкових рахунках водіїв"}}):_vm._e()],1)],1)],1):_vm._e()],1),_c('home-overdrafts',{attrs:{"data":{
      overdrafts: [
          _vm.ovedraftCash,
          _vm.ovedraftCashless
      ]
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }