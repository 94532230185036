<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Картки
        </b-card-title>
        <div class="earnings-card-subtitle font-small-2 d-flex align-items-center mt-2 mb-2">
          <span>Готівкові картки:</span>
          <h5 class="m-0 ml-1">
            {{ data.series[0] }}
          </h5>
        </div>
        <div class="earnings-card-subtitle font-small-2 d-flex align-items-center">
          <span>Безготівкові картки:</span>
          <h5 class="m-0 ml-1">
            {{ data.series[1] }}
          </h5>
        </div>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#F7820D'
const $earningsStrokeColor3 = '#ff9f41'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [1, 1],
          labels: ["Готівкові", 'Безготівкові'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val, total) {
                      let tot = total.config.series.reduce((a,b) => parseInt(a) + parseInt(b), 0)
                      return `${parseInt(val/tot*100)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Всього',
                    formatter(val) {
                      return val.config.series.reduce((a,b) => parseInt(a) + parseInt(b), 0)
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>

<style scoped>

  .earnings-card-subtitle span {
    width: 140px;
  }

</style>