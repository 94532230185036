import store from '@/store'
import {computed, onBeforeMount, reactive, toRefs} from '@vue/composition-api';
import BusinessSettingsService from "@/services/businessSettings/businessSettings.service";

export function useBusinessSettings() {
    const data = reactive({
        discountsDefault: null,
        notifiers: null,
        isUserNotificationActive: false,
        discountsCurrent: null,
        companyType: null,
    })

    const fetchBusinessSettings =  async () => {
        await store.dispatch("businessSettings/fetchCommonSettings");
        data.discountsDefault = store.getters["businessSettings/GET_DEFAULT_DISCOUNTS"];
        data.notifiers = store.getters["businessSettings/GET_COMMON_NOTIFIERS"];
        data.discountsCurrent = store.getters["businessSettings/GET_CURRENT_DISCOUNTS"];
        data.companyType = store.getters["businessSettings/GET_COMPANY_TYPE"];
    };

    const isEndUser = computed(() => {
        const companyType = store.getters["businessSettings/GET_COMPANY_TYPE"];
        return +companyType === 1
    })

    const handleNotificationsForUsers = async (condition) => {
        const params = {
            automatically: {
                onDiscountUpdate: condition
            }
        }

        try {
            const response = await BusinessSettingsService.notifiers(params);
            if (response.result) {
                await fetchBusinessSettings();
            }
        }
        catch (error) {
            return error;
        }

    };

    const setNotificationActive = () => {
        data.isUserNotificationActive = data.notifiers?.automatically?.onDiscountUpdate || false
    }

    const accountTypes = computed(() => {
        return store.getters["businessSettings/GET_ALLOWED_PAYMENT_TYPES"];
    })

    onBeforeMount(async() => {
        await fetchBusinessSettings();
        setNotificationActive();
    });

    return {
        ...toRefs(data),
        isEndUser,
        accountTypes,
        handleNotificationsForUsers,
    };

}