<template>
  <section id="dashboard-home">
    <b-row class="match-height">

      <b-col
        lg="4"
      >
        <b-row class="match-height centered">
          <b-col :lg="isAllowedAccount ? '6' : '12'" md="4" v-if="isAllowedCashAccount">
            <home-order-chart :key="String(isAllowedAccount)" :data="cash"/>
          </b-col>
          <b-col :lg="isAllowedCashAccount ? '6' : '12'" md="4" v-if="isAllowedAccount">
            <home-profit-chart :data="cashless"/>
          </b-col>
          <b-col md="6" lg="12" v-if="statistics.cards"> 
            <home-earnings-chart :data="{
              series:[
                statistics.cards.byTypeAccounts['1'],
                statistics.cards.byTypeAccounts['2']
              ]}" 
            />
          </b-col>
        </b-row>

      </b-col>

      <b-col lg="4" v-if="statistics.drivers">
        <home-drivers :data="statistics.drivers.byStatuses" />
      </b-col>

      <b-col
        lg="4"
        md="4"
        class="home-balances"
        v-if="statistics.balances"
      >
        <b-row class="match-height">
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              icon="DollarSignIcon"
              :statistic="parseFloat(statistics.balances.total[1]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на готівкових рахунках"
              color="info"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              v-if="isAllowedAccount"
              color="warning"
              icon="CreditCardIcon"
              :statistic="parseFloat(statistics.balances.total[2]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на безготівкових рахунках"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              color="danger"
              icon="DollarSignIcon"
              :statistic="parseFloat(statistics.balances.totalDriversOnly[1]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на готівкових рахунках водіїв"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
            sm="6"
          >
            <statistic-card-vertical
              v-if="isAllowedAccount"
              color="primary"
              icon="CreditCardIcon"
              :statistic="parseFloat(statistics.balances.totalDriversOnly[2]/100).toFixed(2) + ' грн'"
              statistic-title="Всього на безготівкових рахунках водіїв"
            />
          </b-col>
        </b-row>
      </b-col>

    </b-row>
    <home-overdrafts
      :data="{
        overdrafts: [
            ovedraftCash,
            ovedraftCashless
        ]
      }"
    />
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import { getUserData } from '@/services/auth/utils';
import HomeOrderChart from './HomeOrderChart.vue';
import HomeProfitChart from './HomeProfitChart.vue';
import HomeEarningsChart from './HomeEarningsChart.vue';
import HomeDrivers from './HomeDrivers.vue';
import HomeOverdrafts from "@/views/dashboard/home/HomeOverdrafts";
import axiosIns from '@axios';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import {useBusinessSettings} from '@/composition/businessSettings/useBusinessSettings';

export default {
  components: {
    BRow,
    BCol,
    HomeOrderChart,
    HomeProfitChart,
    HomeEarningsChart,
    HomeDrivers,
    StatisticCardVertical,
    HomeOverdrafts
  },
  data() {
    return {
      data: {},
      cash: '',
      cashless: '',
      statistics: {},
      ovedraftCash: null,
      ovedraftCashless: null
    };
  },
  methods: {
    async getBalances() {

      const response = await axiosIns
        .get('/business/company/balances')
        .then(response => {
          this.cash = (response.data.wallet.filter(balance => balance.cashType == 1)[0].balance / 100).toFixed(2);
          this.cashless = (response.data.wallet.filter(balance => balance.cashType == 2)[0].balance / 100).toFixed(2);
          this.ovedraftCash = (response.data.wallet.filter(balance => balance.cashType == 1)[0].overdraft / 100).toFixed(2);
          this.ovedraftCashless = (response.data.wallet.filter(balance => balance.cashType == 2)[0].overdraft / 100).toFixed(2);
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return false;
        });
    },
    async getDashboard() {

      const response = await axiosIns
        .get('/business/dashboard/common')
        .then(response => {
          this.statistics = response.data.statistics;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Помилка при отриманні аналітики',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return false;
        });

      this.statistics.drivers.byStatuses = this.statistics.drivers.byStatuses.filter(driver => {
        return driver.count !== 0;
      });
    },
  },
  computed: {
    isAllowedAccount() {
      return !!this.accountTypes && this.accountTypes.includes(2);
    },
    isAllowedCashAccount() {
      return !!this.accountTypes && this.accountTypes.includes(1);
    }
  },
  setup() {
    const toast = useToast();
    const { accountTypes } = useBusinessSettings();

    return {
      toast,
      accountTypes,
    };
  },
  created() {
    this.getBalances();
    this.getDashboard();
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data;

        const userData = getUserData();
        this.data.congratulations.name = userData.fullName;
      });
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.home-balances .card {
  height: 180px;
}

.home-balances .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-balances h2 {
  font-size: 16px;
}

.home-balances span {
  font-size: 12px;
}

.centered {
  justify-content: center
}

</style>
