<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Овердрафт
        </b-card-title>
        <div class="earnings-card-subtitle font-small-2 d-flex align-items-center mt-2 mb-2">
          <span>Готівкові картки:</span>
          <h5 class="m-0 ml-1">
            {{ data.overdrafts[0] }}
          </h5>
        </div>
        <div class="earnings-card-subtitle font-small-2 d-flex align-items-center">
          <span>Безготівкові картки:</span>
          <h5 class="m-0 ml-1">
            {{ data.overdrafts[1] }}
          </h5>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>

  .earnings-card-subtitle span {
    width: 140px;
  }

</style>
